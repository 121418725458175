import React from "react";
import dateFormat from "dateformat";

import Link from "../../components/link";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import newsletters from "../../newsletters";

const IndexPage = () => (
  <Layout>
    <SEO
      title={""}
      keywords={[
        "Electron",
        "newsletter",
        "apps",
        "job offers",
        "node libraries"
      ]}
    />

    <div className="p-4 pt-0 max-w-sm m-auto">
      <h2>Newsletter archive</h2>

      {newsletters.map(item => (
        <div className="mb-4">
          <p className="text-left m-0 p-0">
            <b>Issue {item.issue}</b> &ndash;{" "}
            <em>{dateFormat(new Date(item.date), "mmmm dS yyyy")}</em>
          </p>
          <h3 className="text-left m-0 p-0">
            <Link
              to={`/archive/viewer#issue=${item.issue}`}
              className="text-electron hover:text-teal-darker"
            >
              {item.title}
            </Link>
          </h3>
        </div>
      ))}
    </div>
  </Layout>
);

export default IndexPage;
